import React from 'react';
import { Link } from 'react-router-dom';

class HomeHeader extends React.Component{
  render() {
    const heroContentStyle = {
      backgroundImage: "url(/images/header-bg.jpg)",
    }

    return (
      <section className="home-hero-content" style={ heroContentStyle }>
        <div className="home-hero-content-inner">

          <div className="home-hero-l">
            
            <div className="home-hero-logo-small">
              <div className="subpagelogo">
                <img className="subpagelogo-image" src="/images/chrispic2.jpg" />
                <div className="subpagelogo-right">
                  <div className="subpagelogo-name">Christopher Smith</div>
                  {/*<div className="subpagelogo-title">Sr Product Designer</div>*/}
                </div>
              </div>
            </div>

            <div className="home-hero-name">Christopher Smith</div>
            {/*<div className="home-hero-role">Sr Product Designer</div>*/}
            
            <div className="home-hero-info">
              <p>I'm a multidisciplinary product designer and leader with a strong foundation of computer programing. Currently, I'm leading design and research for a major player in the fitness software space.</p>
              <p>Over my 20+ year career, I've co-founded successful ventures, collaborated with innovators across many industries, built excellent teams, and designed or programmed digital products for some of the largest brands on the planet.</p>
              <p>I'm very interested in the intersection of design + artificial intelligence.</p>
            </div>
          </div>
          <div className="home-hero-r">
            <img className="home-hero-image" src="/images/chrispic2.jpg" />
          </div>

        </div>
      </section>
    );
  }
}

export default HomeHeader;