import React from 'react';
import { Link } from 'react-router-dom';

// import { ReactComponent as IconFileContract } from '../icons/file-contract.svg';
import { ReactComponent as IconDoc } from '../icons/doc.svg';

class Footer extends React.Component{
  render() {
    return (
      <section className="hero-content footer">
        <p><b>Christopher Smith</b> is a multidisciplinary product designer currently leading design and research for multiple products at Xplor Technologies.</p>
        <p>Reach out on LinkedIn: <a target="_blank" href="https://www.linkedin.com/in/atebit/">https://www.linkedin.com/in/atebit/</a></p>
{/*
        <div>
          <Link className="footer-link" target="_blank" to="/files/ChristopherSmith_Resume_2024.pdf">
            <IconDoc />
            <span>Resumé</span>
          </Link>
        </div>
*/}
      </section>
    );
  }
}

export default Footer;