import React, {Component} from 'react';
import SubpageHeader from '../header/SubpageHeader';
import Footer from '../Footer';

import { ReactComponent as IconInfo } from '../../icons/info.svg';
import { ReactComponent as IconTrendUpSquare } from '../../icons/trend-up-square.svg';

class TrueCoachFeatureAdoption extends Component{
  constructor(props){
    super(props);
    this.viewName = "TrueCoachFeatureAdoption";
  }
  componentDidUpdate(){
    // console.log("did update", this);
  }
  componentDidMount(){
    // console.log("did mount", this);
    window.scrollTo({ top: 0 });
  }

  render(){
    return(
      <div >
        <SubpageHeader eyebrowText={"Case Study"} pageHeaderText={"TrueCoach Feature Adoption"}  />

        <section className="hero-content subpage-hero">
          <img src="/images/tc-feature-adoption.jpg" />
        </section>

        <section className="content">

          <h2 className="">
            {/*<IconInfo className="heading-icon1" />*/}
            <span>Background</span>
          </h2>

          <p>The TrueCoach platform aimed to enhance user engagement and maximize the value of new features. As part of this initiative, we focused on refining our approach to feature onboarding and adoption. The objective was to ensure users could easily discover and utilize new features, thereby driving greater platform engagement and satisfaction.</p>
          <p>As a product team, TC had not been releasing features until I joined the team for quite a while. Once I joined and we started releasing features frequently, we noticed that some features didn't get the adoption that we wanted them to. This isn't great—espeicially because one of our core metrics for doing new features is user adoption.</p>

          <h2 className="spacer-above">Better Feature Announcements</h2>
          <p>We enhanced the visibility of new features by creating a more effective in-platform advertising template for marketing and support teams.</p>
          <img className="inline-img" src="/images/tc-adoption-nav.jpg" alt="" />
          <div className="img-annotation">Image note: Feature adoption promo image for announcements of new navigation structure.</div>

          <h2 className="spacer-above">Feature Onboarding Walkthroughs</h2>
          <p>We designed and implemented a template and system for product walkthroughs within Intercom. This system provided users with step-by-step guidance when encountering new features for the first time. To ensure the effective use of this system, I conducted training sessions for the support team.</p>
          <img className="inline-img" src="/images/tc-adoption-walkthrough.jpg" alt="" />
          <div className="img-annotation">Image note: Intercom feature walkthrough tours for features as we roll them out, as well as initial onboarding</div>

          <h2 className="spacer-above">Draw Attention in the UI</h2>
          <p>Additionally, we introduced a tagging and flagging system to highlight new features within the navigation, making them more accessible and engaging for users.</p>
          <img className="inline-img" src="/images/tc-adoption-ui.jpg" alt="" />
          <div className="img-annotation">Image note: Feature adoption promo image for announcements of new navigation structure.</div>

          <h2 className="spacer-above">Standardized Feature Release Checklist</h2>
          <p>The more important part of most of this is the process part internally. We needed to standardize a "release checklist" for all of us to see per each project that considers the release "complete".</p>
          <img className="inline-img" src="/images/tc-adoption-checklist.jpg" alt="" />
          <div className="img-annotation">Image note: Feature adoption promo image for announcements of new navigation structure.</div>
          <p>This proved to be a huge leg up for us remembering what needs to be designed for each feature release, big or small.</p>

          <h2 className="spacer-above">
            {/*<IconTrendUpSquare className="heading-icon1" />*/}
            <span>Results</span>
          </h2>
          
          <ul>
          <li><b>Subscription upgrades:</b> A great example is that we didn't add the "new" badge to the UI for our <a href="/case-studies/truecoach-revenue-features">Wearables feature</a> when it was rolled out. A month later, I added it and within a few hours we had from <b>~15 adopters</b> to <b>~400 adopters</b>, <b>10% of which had updated their account subscription</b> to a higher tier </li>
          <li><b>Refined Feature Onboarding:</b> The new product walkthrough system within Intercom provided clearer guidance to users, leading to a smoother onboarding experience for new features.</li>
          <li><b>Effective Feature Promotion:</b> Improved in-platform advertising and the tagging system for new features increased user awareness and engagement, contributing to a more dynamic user experience.</li>
          </ul>
      

        </section>
        <Footer />
      </div>
    );
  }
}


export default TrueCoachFeatureAdoption;
