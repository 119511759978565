import React, {Component} from 'react';
import { Link } from 'react-router-dom';

// import { ReactComponent as IconInfo } from '../../icons/info.svg';

class SubpageHeader extends Component{
  constructor(props){
    super(props);
    this.eyebrowText = this.props.eyebrowText;
    this.pageHeaderText = this.props.pageHeaderText;
  }
  componentDidUpdate(){ }
  componentDidMount(){ }

  render(){
    
    const heroContentStyle = {
      backgroundImage: "url(/images/header-bg.jpg)",
    }

    return(
      <div className="subpage-top" style={ heroContentStyle }>
        <div className="hero-content">

          <Link className="right-menu" to="/">
            <img className="right-menu-img" src="/images/icon-home-white.png" />
          </Link>

          <Link to="/">
            <div className="subpagelogo">
              <img className="subpagelogo-image" src="/images/chrispic2.jpg" />
              <div className="subpagelogo-right">
                <div className="subpagelogo-name">Christopher Smith</div>
                {/*<div className="subpagelogo-title">Sr Product Designer</div>*/}
              </div>
            </div>
          </Link>
          
          <div className="subpage-title">
              <div className="subpage-eyebrow">{this.eyebrowText}</div>
              <div className="subpage-header">{this.pageHeaderText}</div>
          </div>
        </div>
      </div>
    );
  }
}


export default SubpageHeader;
