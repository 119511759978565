import React, {Component} from 'react';
import SubpageHeader from '../header/SubpageHeader';
import Footer from '../Footer';

import { ReactComponent as IconInfo } from '../../icons/info.svg';
import { ReactComponent as IconTrendUpSquare } from '../../icons/trend-up-square.svg';
import { ReactComponent as IconTarget } from '../../icons/target.svg';
import { ReactComponent as IconPhone } from '../../icons/phone.svg';

class TrueCoachMobileOverhaul extends Component{
  constructor(props){
    super(props);
    this.viewName = "TrueCoachMobileOverhaul";
  }
  componentDidUpdate(){
    // console.log("did update", this);
  }
  componentDidMount(){
    // console.log("did mount", this);
    window.scrollTo({ top: 0 });
  }
  
  render(){
    return (
      <div >
        <SubpageHeader eyebrowText={"Case Study"} pageHeaderText={"TrueCoach Mobile Products Overhaul"}  />

        <section className="hero-content subpage-hero">
          <img src="/images/tc-mobile.jpg" alt="TrueCoach Mobile app hero"  />
        </section>

        <section className="content">

          <h2 className="">
            {/*<IconInfo className="heading-icon1" />*/}
            <span>Background</span>
          </h2>

          <p>TrueCoach’s athlete apps needed a significant overhaul to meet the evolving needs of our users and the growing demands of the business. Leveraging extensive user research and usability testing, we embarked on creating a modern design system that was compatible with both Android and iOS-specific design languages.</p>            
          
          <p>When I joined TrueCoach, the iOS app rating was languishing at a mere 2.5⭐ and Android was still in Beta. This low rating was a result of various factors, including numerous bugs, a dated user experience, and a lack of confidence in feature releases. We tackled these issues head-on by fixing critical bugs, completely redesigning the user experience, and implementing a robust new design system. Our efforts paid off, and we saw the app rating soar to an impressive 4.8⭐. This substantial improvement not only boosted user satisfaction but also unlocked significant marketing opportunities for our team.</p>

          <div className="blockquote-large">
            <p><b>tl;dr</b><br/>
            <i>Got the mobile app ratings way up:</i><br/>
            <b>⭐ 2.3</b> to <b>4.8</b> for iOS<br/>
            <b>⭐ beta (0)</b> to <b>4.8</b> for Android</p>
          </div>

          <h2 className="spacer-above">
            {/*<IconTarget className="heading-icon1" />*/}
            <span>Primary Goals</span>
          </h2>

          <ul>
              <li>Redesign athlete apps using a consistent and modern design system.</li>
              <li>Achieve compatibility with Android and iOS design languages.</li>
              <li>Adhere to TrueCoach branding while inheriting from the Apollo Design System.</li>
          </ul>

          <img className="inline-img" src="/images/tc-app-design-process.jpg" alt="Design process Figma screenshot for TrueCoach app" />
          <div className="img-annotation">Image note: The design process can get messy.</div>
          <p><b>Note:</b> All of these were tackled within 6 months of my being at Xplor. After that, it was time to get to work on some new value for coaches.</p>
          <img className="inline-img" src="/images/tc-oldnewmobile.jpg" alt="Before & After screenshots of mobile TrueCoach" />


          <h1 className="spacer-above">
            {/*<IconPhone className="heading-icon1" />*/}
            <span>Coaching on the Go</span>
          </h1>

          <p>Once we had gotten past the first hurdle of making the app not negative for the company and for our users, I looked forward with the team to prioritize how we might extend the mobile features for a more robust "coaching on the go" application.</p>
          <p>Our coaches had a long backlog of product requests for mobile, so we started strategically organizaing them to drive value to coaches and their clients. With each feature set, we used the design thinking approach: research, design, test, adjust design, develop, test, repeat...</p>
          
          <h2 className="spacer-above">Mobile Coach Features</h2>
          <p>The core business of TrueCoach revolves around coaching, so it was essential to enhance this functionality on mobile to provide value to coaches, particularly those working remotely.</p>
          <p>We didn't want to build every feature available on the web, that would be too much cognitive overload for users. So instead we boiled down the most important features by surveying and interviewing coaches about how they user their mobile device on the go and what their clients ask them to do most often as it relates to building and editing workouts.</p>
          
          <p><b>"TrueCoach Connect" Integration & Deprecation</b><br/>TrueCoach had a failing app called “Connect,” built with ReactNative, which allowed coaches to message their clients and see workout activity notifications. However, it was buggy, not widely adopted and we didn't have the development resources to maintain it. We made the strategic decision to integrate its features into the main app to provide a more cohesive user experience.</p>
          <p><b>Workout Feedback</b><br/>The main features that came from "TrueCoach Connect" were workout feedback features and direct messaging features. We integrated both into the main application, starting with working feedback.</p>
          <img className="inline-img" src="/images/tc-workoutfeedback.jpg" alt="TrueCoach workout feedback" />
          <p><b>Group Messaging</b><br/>Coaches wanted the ability to send messages to groups of people at the same time and/or broadcast a message to a bunch of individuals within the TrueCoach messaging. We enhanced the communication flow between coaches and their clients, allowing for seamless group interactions and coach-to-many broadcast messaging.</p>
          <img className="inline-img" src="/images/tc-grp-msgs.jpg" alt="TrueCoach group messaging" />
          
          <p className="spacer-above"><b>TrueCoach App(s) Re-Brand</b><br/>Once we had ported over all the features from the connect app, we deprecated it, let the customers know and then rebranded our "TrueCoach for Clients" app to just "TrueCoach" in the app stores giving us a unified brand experience for both Coaches and their Client athletes.</p>

          <p>This was an "all necessary features are complete" line in the sand. Which meant, finally, after a year and a half, it was time to start designing and building programming and admin features for coaching while on the go!</p>

          <p><b>Workout Program Building</b><br/>We developed mobile-friendly features for coaches to create and edit workout programs. This involved extensive design usability iterations to ensure we built “the most important” programming features for coaches, providing a highly intuitive and efficient experience.</p>
          <img className="inline-img" src="/images/tc-workoutbuilder.jpg" alt="TrueCoach workout builder" />

          <p>One of my favorite parts of the workout builder, is the drag and drop editor for exercises. It went through a handful of different variants, but landed on a pretty user friendly version.</p>
          <img className="inline-img" src="/images/tc-workoutbuilder-dnd.jpg" alt="TrueCoach workout builder drag and drop" />
          

          <p><b>Mobile Client Management</b><br/>Recognizing the need for coaches to manage their clients effectively, I designed, roadmapped, and began the gradual rollout of client administration features. These features allow coaches to handle the creation and moderation of their clients directly from the mobile app, streamlining their workflow and improving efficiency.</p>
          <img className="inline-img" src="/images/tc-mng-clients.jpg" alt="TrueCoach client management" />
          
          <p><b>Custom Coach Branded Apps</b><br/>To enhance brand presence and client engagement, I designed a streamlined system that allows coaches to brand their client-facing apps with their colors and logos. This gated feature provides an upselling opportunity, allowing coaches to offer a personalized app experience to their clients.</p>
          <p>1. Give the coaches a clean and easy to understand UI.</p>
          <img className="inline-img" src="/images/tc-branding-admin.jpg" alt="TrueCoach custom branding for coaches" />
          <p>2. Give the devs a clear understanding of all the things that need to change throughout the athlete experience.</p>
          <img className="inline-img" src="/images/tc-branding-client.jpg" alt="TrueCoach custom branding for athletes" />
          <p>3. Develop the ability to cascade the color variables throughout the Figma design file and potentially see how new features handle with a standardized color variable system. As well as design, I also gave the developers the algorithm that would recieve a given hex color and give a boolean response for whether or not it should have light or dark text on it. I gave a recommendation, but I also gave them the algorithm in all necessary languages (<a target="_blank" href="https://gist.github.com/atebit/843323862b8325430dae324d34b7aeec">Ruby</a>, <a target="_blank" href="https://gist.github.com/atebit/08a9d518516d444b86a7feaa4e8dd718">Javascript</a>, <a target="_blank" href="https://gist.github.com/atebit/0f9fc1418fc97cc7c8f27e6f17a186f9">iOS</a>, <a target="_blank" href="https://gist.github.com/atebit/8aa23a731532a71d3fc42162ed3f571e">Android</a>).</p>
          <img className="inline-img" src="/images/tc-branding-figma.jpg" alt="TrueCoach custom branding for coaches" />
          
          <h2 className="spacer-above">How can we innovate?</h2>
          <p>Once we designed and developed what I like to call the "core necessary features" that coaches required in a strong mobile app—we were opened up to thinking about new ways to use the mobile apps to innovate for the product suite.</p>
          <p><b>Global Media Upload Queue</b><br/>We addressed the longstanding issue of a clunky media uploading system by redesigning both the data flow and the user interface. The new global upload queue efficiently handles all uploads within the app in the background freeing up the user to navigate and do their tasks while having a notification system to alert them of issues. I suggested the high-level Object-Oriented Programming patterns for developers to implement, ensuring a seamless and intuitive user experience.</p>
          <img className="inline-img" src="/images/tc-problem-solving.jpg" />
          <p><b>AI Recommendation Engine</b><br/>We've been testing some pretty awesome AI features that scrape client data, find patterns in workouts over time, habits over time, etc and give potential recommendations to coaches about how to adjust their clients programming.</p>
          <p><i>At the time of writing this, I cannot share visual design publicly.</i></p>
          <p><b>Mobile Coach Onboarding</b><br/>To expand our market reach, I designed and outlined features that transform our mobile app into part of the sales funnel. As it stands, the TrueCoach mobile app requires users to create an account on the web interface before they can log in. I developed user flows that enable potential new coaches browsing the app store to onboard directly through the app, opening up new avenues for growth.</p>
          <p><i>At the time of writing this, I cannot share visual design publicly.</i></p>
          <p><b>Mobile Athlete “Self-Coaching” Features</b><br/>We initiated a project to provide self-coaching features for users without personal trainers. Using the technology and design built for coaching features, we offer a limited version of these functionalities to self-coaching athletes. This approach unlocks a larger tangential market and allows users to transition into using a personal trainer, facilitating upselling into the TrueCoach trainer community.</p>
          <p><i>At the time of writing this, I cannot share visual design publicly.</i></p>
          
          <h2 className="spacer-above">
            {/*<IconTrendUpSquare className="heading-icon1" />*/}
            <span>Results</span>
          </h2>

          <div className="results">
              <ul>
                  <li><strong>App Store Rating Improvement:</strong> We successfully improved the app ratings from <b>2.5⭐ to 4.8⭐ for iOS</b>  and <b>0 to 4.8⭐ for Android</b>, significantly boosting user satisfaction and unlocking marketing potential.</li>
                  <li><strong>Enhanced Coach and Athlete Satisfaction:</strong> Through the integration of robust features, we improved the overall user experience for both coaches and their clients.</li>
                  <li><strong>Operational Efficiency:</strong> By streamlining release processes and future-proofing app functionalities, we enhanced operational efficiency and positioned TrueCoach for sustained growth.</li>
                  <li><strong>Design Team Efficiency:</strong> By building a set of reusable or at the very least duplicatable components within a TrueCoach branch of the Apollo Design System, we save ourselves time for each new feature set or feature exploration both within TrueCoach—but also across the other Xplor brands and products.</li>
                  <li><strong>Set up for the future:</strong> having a solid foundation allows for innovation to take over and come up with new product ideas for the customers, not just "keep up with the Joneses"</li>
              </ul>
          </div>


          <h2 className="spacer-above">What I've learned personally</h2>
          <ul>
            <li>Personal Trainers are pretty savvy folks. So open design data entry patterns work well for them, but having an app that is more opinionated helps their athlete clients far better.</li>
            <li>Re-designing an application that has 500k+ daily active users or more is something that needs to be thought through from a macro level, then boiled down to the smallest changes and iterations that give users value or remove complexities. </li>
            <li>Having the foresight to see what a product could be in the future, and having the skills to design that are one thing. Making sure the team and leadership are onboard with you is a very different challenge. I had to break down my designs and thoughts into smaller and smaller bite-sized and often revenue focused initiatives that were sequenced to ultimately get us to where I wanted us to be.</li>
          </ul>
        
          <h2 className="spacer-above">Thanks for reading 🎉</h2>
          <p>We are all pretty busy, so if you made it all the way through this, I salute you. If there is anything you'd like further clarification on or are just kinda curious, feel free to reach out to me.</p>

        </section>
        <Footer />
      </div>
    )
  }
}

export default TrueCoachMobileOverhaul;
