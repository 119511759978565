import React from 'react';

class Header extends React.Component{
  render(){

    const style = {
      backgroundColor: "#F1EDF4",
      backgroundRepeat: "repeat-x",
      backgroundImage: "url(/images/logogarden.jpg)"
      // backgroundPosition: 
    };

    return (
      <section className="logogarden" style={style}></section>
    );
  }
}

export default Header;