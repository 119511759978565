import React, {Component} from 'react';
import SubpageHeader from '../header/SubpageHeader';
import Footer from '../Footer';

import { ReactComponent as IconInfo } from '../../icons/info.svg';
import { ReactComponent as IconTrendUpSquare } from '../../icons/trend-up-square.svg';
import { ReactComponent as IconPuzzle } from '../../icons/puzzle.svg';

class TrueCoachRevenueFeatures extends Component{
  constructor(props){
    super(props);
    this.viewName = "TrueCoachRevenueFeatures";
  }
  componentDidUpdate(){
    // console.log("did update", this);
  }
  componentDidMount(){
    // console.log("did mount", this);
    window.scrollTo({ top: 0 });
  }
  
  render(){
    return (
      <div >
        <SubpageHeader eyebrowText={"Case Study"} pageHeaderText={"TrueCoach Growth Features"}  />

        <section className="hero-content subpage-hero">
          <img src="/images/tc-gated-features.jpg" />
        </section>

        <section className="content">

          <h2 className="">
            {/*<IconInfo className="heading-icon1" />*/}
            <span>Background</span>
          </h2>

          <p>We wanted to employ various strategic upselling mechanisms and feature enhancements that could be gated to persuade our customers to upgrade to higher tiers. Not only for revenue, but to cover the cost of some of the more expensive features that users had been requesting that we build.</p> 

          <p><b>There were hurldes...</b><br/>
          We faced some uphill battles with the engineering architecture of the platform. It just wasn't built for any of that from the outset. So, even though we had thought of a bunch of great ideas for new features, we had to organize them into release schedules that would unlock things sequentially. For example, to build One-Time Payments, we first had to overhaul the underlying structure to utilize a newer Stripe version. We did this by doing a UX project that "cleaned up some sludge" in the flows and tied the engineering overhead to a tangible user value outcome as well.</p> 

          <p><b>Iterate, iterate, iterate.</b><br/>
          With each "small feature" win, we slowly chipped away at the underlying architectural issues and over the course of a year, we were able to roll out features with much less friction. This allowed for experiments and quickly putting out features to see if they bump upsells or not. Each project had it's own problems and solutions, then bumps along the way, but that's just the software development process. I could elaborate for hours on any one of the following feature sets, but I'll keep it consice for this case study.</p>

          <p><b>I Standardized Upselling Mechanisms</b><br/>
          I Designed and implemented upsell patterns across the app and with internal documentation to create a consistent upsell experience managed by the commercial & product teams.</p>

          <ul>
          <li>Allow a user to use gated features while in a free trial</li>
          <li>Always use the "gold gradient" for an upgrade button</li>
          <li>Always prompt the user to upgrade in context of the feature if they do not have access to it</li>
          <li>New featuare annoncements should drive to educational information about the new feature and if applicable an immediate upgrade CTA</li>
          </ul>

          <h2 className="spacer-above">
            {/*<IconPuzzle className="heading-icon1" />*/}
            <span>What features did we build?</span>
          </h2>

          <h3>Habit Tracker</h3>
          <p>TrueCoach had a nutrition tracker built in, but it was very limited. As well, there was not an easy way to input the information from an athlete perspective. The feature was tossed into a "settings junk drawer" without a proper UX flow.</p>
          <p>I sent out a few preliminary surveys, then interviewed 10 coaches to learn about their processes. I wanted to learn:</p>
          <ul>
            <li>What do you use other than TrueCoach to track your clients non-workout and non-nutrition tasks? Such as sleep, energy levels, sentiment, etc.</li>
            <li>What do you use other than TrueCoach to track your clients nutritional macros?</li>
            <li>What softwares do you use other than TrueCoach to help with these? Spreadsheets, other coaching softwares, etc?</li>
          </ul>

          <p>From that research, I was able to determine the commonalities of the the power users at TC, I then designed software that was somewhere in the middle of all of them. The interesting thing is that the advanced users weren't gonna use it because they had complex systems in place already, but anyone below them would use something that solved "most of the advanced users problems." Which makes for a great upsell feature.</p>

          <p><b>What does it look like?</b></p>
          
          <p>1. Design an easily scannable and editable UI for coaches. Use color coding to help separate nutrition & non-nutrition tasks.</p>
          <img className="inline-img" src="/images/tc-habit-admin.jpg" />

          <p>2. Make the athlete side not only easy, but also pleasent to interact with so it doesn't feel like a chore.</p>
          <img className="inline-img" src="/images/tc-habit-client.jpg" />

          <p>3. Allow athletes (the coaches client) to request that the coach upgrades their account to allow the habit tracker features. This is the first time I got the TrueCoach team to agree to do this level of upselling at the end-user/consumer level. Big win.</p>
          <img className="inline-img" src="/images/tc-habit-upsell.jpg" />

          <p>4. Standaradized announcement that drives coaches to investigate the feature and potentially upgrade their account to use it.</p>
          <img className="inline-img" src="/images/tc-habit-announcement.jpg" />



          <h3 className="spacer-above">Wearable Device Integration</h3>
          <p>We partnered with a third-party copmany called Terra that consumes many wearables and provides a systematic API to integrate the data from those wearable devices, enhancing workout insights for our coaches and athletes.</p>
          <p>The design work for this was a lot more about data flow, where connections happen, etc and less about the front-end UI design.</p>
          <img className="inline-img" src="/images/tc-wearables-data.jpg" />
          <div className="img-annotation">Image note: A bunch of screenshots of where the majority of my design efforts went: diagraming.</div>
          <p>Though, the UI design was important, it was more about <i>WHEN</i> the data was <i>WHERE</i>.</p>
          <img className="inline-img" src="/images/tc-wearables-user.jpg" />

          <h3 className="spacer-above">Payments</h3>
          <p><strong>Payment System UX:</strong> Redesigned the UX flows for TrueCoach payments to address broken links and incoherent steps, resulting in a 10% increase in adoption rates. This brought a small amount of revenue via processing payments for coaches. This one technically isn't a gated feature, but we had to do it because we wanted to expand our payment system to allow for one-time payments and that meant we needed a preliminary project to overhaul the underlying Stripe architecture.</p>
          <img className="inline-img" src="/images/tc-payment-sludge.jpg" />
          <div className="img-annotation">Image note: Incredibly difficult to follow paths in this diagram.</div>
          <p>From that above nonsense to this simplified 3 step process for users to create and sell subscriptions. Sheesh. Why was such an important part designed so bad 🤣🤣🤣??</p>
          <img className="inline-img" src="/images/tc-payment-revision.jpg" />
          <div className="img-annotation">Image note: Notice out the old web UI that wasn't updated yet?</div>
          <p><strong>One-Time Payments:</strong> Once having the underlying structure fixed, we expanded payment capabilities to include sales of one-time invoices and payments. For example: merchandise, supplements, and bulk training programs sales.</p>
          <img className="inline-img" src="/images/tc-payment-onetime.jpg" />

          <h3 className="spacer-above">Zapier Integration</h3>
          <p>Developed triggers and actions for Zapier integration, enabling automation of data transfers and improving workflow efficiency.</p>
          <p><strong>Triggers:</strong> Added triggers for new client additions, workout creation, and completion. This allows coaches to export some of their data to their own spreadsheets or other softwares via Zapier APIs.</p>
          <p><strong>Actions:</strong> Created a Lead Management feature set for tracking and managing leads through Zapier. This ultimately would grow into a more full-featured CRM to handle a new leads section of the platform.</p>
          <img className="inline-img" src="/images/tc-zaps.jpg" />

          <h3 className="spacer-above">Lead Management</h3>
          <p>Implemented features to integrate new client leads into the platform, including leads from coach profiles and external sources. This facilitated better CRM management and expanded business opportunities.</p>
          <img className="inline-img" src="/images/tc-lead-management.jpg" />


          <h3 className="spacer-above">Coach Profiles</h3>
          <p>Developed public coach profiles, allowing coaches to create squeeze pages and link to them from their respective funnel locations (usuall social media) and attract new clients.</p>
          <img className="inline-img" src="/images/tc-profile-front.jpg" />

          <p><b>Information Architecture Note:</b> Once I started putting together the public profiles, the lead management stuff, etc—I realized that all of the tools related to marketing for a coach needed to be reorganized into their own main-level navigation section for "Marketing Tools".</p>
          <img className="inline-img" src="/images/tc-profile-reorg.jpg" />

          <p><b>Interesting Note:</b> Coach Profiles and Lead Management were designed by me when I first got to the team. We designed and built a separate product to try and create a marketplace. However, it was hastily rolled out because of prior timelines by upper management before my time. It ultimately failed and we dropped it. Then, 2.5 years later, once we had ironed out payment issues, leads within the platform and the like, we came back to these features to roll them out inside of the product instead of as a separate offering within Xplor.</p>

          <h2 className="spacer-above">
            <IconTrendUpSquare className="heading-icon1" />
            <span>Results</span>
          </h2>

          <ul>
            <li><strong>Increased Feature Adoption:</strong> The introduction of gated features and improved upsell patterns led to growth in feature usage and revenue.</li>
            <li><strong>Enhanced User Engagement:</strong> Redesigns of payment systems and habit tracking features resulted in a more intuitive user experience and greater engagement.</li>
            <li><strong>Expanded Platform Capabilities:</strong> Integrations with wearable devices and Zapier enhanced platform functionality to meet diverse user needs.</li>
            <li><strong>Coach Marketing Capabilities:</strong> The coach profiles and improved lead management features helped coaches increase their visibility and attract new clients—contributing to their business growth—<i>which ultimately is our business growth</i>.</li>
          </ul>


          <h2 className="spacer-above">What I've learned personally</h2>
          <ul>
            <li>Anthing to do with payment systems will take longer than you expect it to from the engineering side of things.</li>
            <li>Not all features are going to be valuable enough for an upsell conversion—even if the users say they are in surveys and in research. For example, we built a collection of features to enhance our messaging platfrom from just DMs to full group and broadcast messaging. We gated it for a month and only a handful of users upgraded. So, we opened it up to everyone and adoption went way up.</li>
          </ul>


        
          <h2 className="spacer-above">Thanks for reading 🎉</h2>
          <p>I realize that this is a ton of information and I could do a case study on each one of these individually. But, honestly, there's just not enough time in the day and I'd rather give an overview of how much work is being done towards a larger goal with each one of these "smaller" (months long) initiatives. If there is anything you'd like further clarification on or are just kinda curious, feel free to reach out to me.</p>


        </section>
        <Footer />
      </div>
    )
  }
}

export default TrueCoachRevenueFeatures;
