import React from 'react';

import { ReactComponent as IconPenTool } from '../icons/pen-tool.svg';
import { ReactComponent as IconGuitar } from '../icons/guitar.svg';
// import { ReactComponent as IconLayer } from '../icons/layer.svg';
// import { ReactComponent as IconBulb } from '../icons/bulb.svg';
import { ReactComponent as IconUnbox } from '../icons/unbox.svg';
// import { ReactComponent as IconRocket } from '../icons/rocket.svg';
import { ReactComponent as IconCoffee1 } from '../icons/coffee-cup-1.svg';
import { ReactComponent as IconClock } from '../icons/clock.svg';
import { ReactComponent as IconKey } from '../icons/key.svg';
import { ReactComponent as IconPencilRuler } from '../icons/pencil-ruler.svg';


import { Link } from 'react-router-dom';


class CaseStudies extends React.Component{
  render(){
    return (
      <div>

        <section className="content">

          <h1 className="">
            {/*<IconPenTool className="heading-icon1" />*/}
            <span>Case Studies</span>
          </h1>

          <p><b>Design & Research @ Xplor Technologies</b><br/>
          Here are a few case studies that highlight some of the things I’ve done at Xplor to improve the <a href="https://truecoach.co" target="_blank">TrueCoach</a> product over the past 3 years.</p>
          
        </section>

        <section className="content">

          <Link className="hp-work-item" to="/case-studies/truecoach-mobile-overhaul">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/tc-casethumb-mobile.png)" }}>
              </div>
              <div className="hp-work-content pure-g">
                <div className="hp-work-col1">
                  <div className="hp-work-title">Mobile Products Overhaul</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI</div>
                  <div className="hp-work-info">Comprehensive overhaul of TrueCoach's mobile apps, resulting in a dramatic improvement in app store ratings, while implementing a modern design system compatible with both platforms.</div>
                </div>
              </div>
              <div className="hp-work-cta">
                <button className="pure-button">View Case Study</button>
              </div>
            </div>
          </Link>

          <Link className="hp-work-item" to="/case-studies/truecoach-revenue-features">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/tc-casethumb-revenue.png)" }}></div>
              <div className="hp-work-content pure-g">
                <div className="hp-work-col1">
                  <div className="hp-work-title">Growth Features</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI</div>
                  <div className="hp-work-info">Led design and implementation of revenue-generating features for TrueCoach, overcoming significant engineering challenges to introduce gated features, upselling mechanisms, and integrations that enhanced user engagement and boosted overall platform revenue.</div>
                </div>
              </div>
              <div className="hp-work-cta">
                <button className="pure-button">View Case Study</button>
              </div>
            </div>
          </Link>

          <Link className="hp-work-item" to="/case-studies/truecoach-web-interface-improvements">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/tc-casethumb-web2.png)" }}></div>
              <div className="hp-work-content pure-g">
                <div className="hp-work-col1">
                  <div className="hp-work-title">Design Systems</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI &#x2022; Programming</div>
                  <div className="hp-work-info">Led design and implementation of revenue-generating features for TrueCoach, overcoming significant engineering challenges to introduce gated features, upselling mechanisms, and integrations that enhanced user engagement and boosted overall platform revenue.</div>
                </div>
              </div>
              <div className="hp-work-cta">
                <button className="pure-button">View Case Study</button>
              </div>
            </div>
          </Link>

          <Link className="hp-work-item" to="/case-studies/truecoach-adoption">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/tc-casethumb-adoption.png)" }}></div>
              <div className="hp-work-content pure-g">
                <div className="hp-work-col1">
                  <div className="hp-work-title">Feature Adoption</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI &#x2022; Programming</div>
                  <div className="hp-work-info">Led the initiative to enhance TrueCoach's feature adoption by implementing effective onboarding walkthroughs, in-platform announcements, and a standardized release checklist, significantly increasing user engagement and subscription upgrades.</div>
                </div>
              </div>
              <div className="hp-work-cta">
                <button className="pure-button">View Case Study</button>
              </div>
            </div>
          </Link>

        </section>

        
        <section className="content">

          <h1 className="spacer-above">
            {/*<IconCoffee1 className="heading-icon1" />*/}
            <span>Entrepreneurship</span>
          </h1>

          <h1 className="spacer-above"></h1>
          <p>Before Xplor I was co-founder of and worked closely with a lot of startups. I may build case studies out of these at some point, but for now, I just want to give them the attention they deserve on the homepage.</p>
        </section>

        <section className="content spacer-above">

          <div className="hp-work-item" to="/case-studies/adjust-creative">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/casethumb-adjust.png)" }}></div>
              <div className="hp-work-content">
                <div className="hp-work-col1">
                  <div className="hp-work-title">Adjust Creative</div>
                  <div className="">Co-Founder</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI &#x2022; Programming &#x2022; Management</div>
                  <div className="hp-work-info">Adjust Creative was a product design agency that created and developed many interactive products and experiences with a people-first approach. We functioned in the areas of user experience, internet, user interface, product design, product strategy, mobile, ruby on rails, javascript, entertainment, music, education, and healthcare etc.</div>
                </div>
              </div>
            </div>
          </div>

          <div className="hp-work-item" to="/case-studies/teamhop">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/casethumb-teamhop.png)" }}></div>
              <div className="hp-work-content pure-g">
                <div className="hp-work-col1">
                  <div className="hp-work-title">TeamHop</div>
                  <div className="">Co-Founder</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI &#x2022; Programming &#x2022; Management</div>
                  <div className="hp-work-info">TeamHop was a SaaS product company that serviced Major League Baseball travel managers and their teams. From travel managers, to coaches, to all the players on the tour, we handled the logistics and communication in our platform.</div>
                </div>
              </div>
            </div>
          </div>

          <div className="hp-work-item" to="/case-studies/urm-academy">
            <div className="hp-work-inner">
              <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/casethumb-urm.png)" }}></div>
              <div className="hp-work-content pure-g">
                <div className="hp-work-col1">
                  <div className="hp-work-title">URM Academy</div>
                </div>
                <div className="hp-work-col2">
                  <div className="hp-work-skills">Strategy &#x2022; Research &#x2022; UX/UI &#x2022; Programming</div>
                  <div className="hp-work-info">URM Academy is an online audio school created by Joey Sturgis, Joel Wanasek and Eyal Levi - the guys who spent years in the studio with bands like The Devil Wears Prada, Chelsea Grin, Of Mice & Men, Born Of Osiris, Asking Alexandria and dozens more of this generation's best metal bands.</div>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className="content">


          <h1 className="spacer-above">
            {/*<IconClock className="heading-icon1" />*/}
            <span>Past Work</span>
          </h1>

          <p>I've worked with some of the largest brands and creative companies on the planet as a freelancer or with consulting agencies. I've taken on the roles of graphic designer, creative director, animator, interactive programmer, full-stack programmer, and more. I'm basically a sponge of creativity and technology.</p>
        </section>
        <section className="hero-content">
          <img className="inline-img" src="/images/christophersmitholdstuff.jpg" />
        </section>
        <section className="content">

          <h2 className="spacer-above">
            {/*<IconPencilRuler className="heading-icon1" />*/}
            <span>Figma Plugins</span>
          </h2>

          <p>Because I work in Figma a lot, I've built a few blugins for it to make life easier for myself and others.</p>
          <ul>
            <li><Link to="">AI Design Critique Tool</Link></li>
            <li><Link to="">Generate Stickies from structured CSV</Link></li>
            <li><Link to="">Generate content for complex components</Link></li>
          </ul>

          <h2 className="spacer-above">
            {/*<IconKey className="heading-icon1" />*/}
            <span>Design & Tech Education</span>
          </h2>

          <p>For quite a few years I've worked as an educator both internally at companies I work with and also in the greater design community.</p>

          <p><b>Designation.io</b><br/>
          I helped a fantastic group of young Chicago-based entrepreneurs start a design education company called Designation. We went from 0-1 quickly having young professionals and students from all around the world coming to Chicago to attend our 12 week in-person bootcamp for Design, UX, and Coding.</p>
          <p>Designation was <a target="_blank" href="https://flatironschool.com/blog/flatiron-school-designation/">ultimately aquired by the Flat Iron School</a> in New York City.</p>
          
          <p><b>AIGA</b><br/>
          I've worked with AIGA intermittently throughout my career to help mentor less experienced designers. In-person in Chicago, remote across the Unied States and Europe, I've helped some bright students get past hurdles, given full bootcamp style classes, month long mentorship programs, or just given advice from a senior designers perspective.</p>
          


          <h1 className="spacer-above">
            {/*<IconGuitar className="heading-icon1" />*/}
            <span>Music & Audio Production</span>
          </h1>

          <p>As long as I've been doing design and programming, I've also been playing guitar and doing music production with computers. I've played in quite a few bands over the years and produced some amazing music with some of my favorite people.</p>
          <p>From trip hop, to R&B, to pop, to jazz, to absolutely brutal metal and prog—my music production portfolio has something in there for almost everyone.</p>
          <p>Find my production playlists on YouTube and Spotify here:</p>

          <div className="music-links-grid-container">
            <div className="music-links-grid">
              <div className="pure-u-1-2">
                <div className="pad-img">
                  <Link target="_blank" to="https://open.spotify.com/playlist/6jLRRcy7IdfjuFTltWsqbz?si=91b9415b93ed4c0c">
                    <img src="/images/music-spotify.png" />
                  </Link>
                </div>
              </div>
              <div className="pure-u-1-2">
                <div className="pad-img">
                  <Link target="_blank" to="https://www.youtube.com/playlist?list=PLJEKQ1nWBzCDOmr9sPECkXJkGsbfVm9r6">
                    <img className="music-link-yt" src="/images/music-youtube.png" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/*<ul className="">
            <li><Link target="_blank" to="https://open.spotify.com/playlist/6jLRRcy7IdfjuFTltWsqbz?si=91b9415b93ed4c0c">Spotify Playlist</Link></li>
            <li><Link target="_blank" to="https://www.youtube.com/playlist?list=PLJEKQ1nWBzCDOmr9sPECkXJkGsbfVm9r6">Youtube Playlist</Link></li>
          </ul>*/}
          

          <h2 className="spacer-above">
            {/*<IconUnbox className="heading-icon1" />*/}
            <span>Audio Plugin VSTs</span>
          </h2>

          <p>Being part of the music industry has put me in a unique position to make some pretty rad audio software too. If you're an audio producer and you use MIDI drum software that sounds like real drums—these are for you.</p>

          <div className="vsts-thumbs">

            <Link className="hp-work-item small" target="_blank" to="https://rsdrums.com/">
              <div className="hp-work-inner">
                <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/vst-rs-monarch-kit.png)" }}></div>
                <div className="hp-work-content pure-g">
                  <div className="hp-work-col1">
                    <div className="hp-work-title">RS Drums</div>
                    {/*<div className=""><b>UX/UI &#x2022; Strategy</b></div>*/}
                  </div>
                  <div className="hp-work-col2">
                    <div className="hp-work-info">I've designed multiple drum software instruments with my friends Anup Sastry and Alex Alex Rüdinger—two of the best drummers to ever do it.</div>
                  </div>
                </div>
              </div>
              <div className="hp-work-cta">
                <button className="pure-button">Visit RS Drums</button>
              </div>
            </Link>

            <Link className="hp-work-item small" target="_blank" to="https://hansenaudio.shop/">
              <div className="hp-work-inner">
                <div className="hp-work-img-container" style={{ backgroundImage: "url(/images/vst-hansen-janzikit.png)" }}></div>
                <div className="hp-work-content pure-g">
                  <div className="hp-work-col1">
                    <div className="hp-work-title">Hansen Audio</div>
                    {/*<div className=""><b>UX/UI</b></div>*/}
                  </div>
                  <div className="hp-work-col2">
                    <div className="hp-work-info">I designed one drum software instrument so far with Jacob Hansen and the drummer Adam Janzi. Incredibly fun and sick sounding!</div>
                  </div>
                </div>
              </div>
              <div className="hp-work-cta">
                <button className="pure-button">Visit Hansen Audio</button>
              </div>
            </Link>
            
          </div>
        </section>
      </div>
    );
  }
}

export default CaseStudies;