import React, {Component} from 'react';
import SubpageHeader from '../header/SubpageHeader';
import Footer from '../Footer';

import { ReactComponent as IconInfo } from '../../icons/info.svg';
import { ReactComponent as IconTrendUpSquare } from '../../icons/trend-up-square.svg';
import { ReactComponent as IconTree2 } from '../../icons/tree-2.svg';
import { ReactComponent as IconShape } from '../../icons/shape.svg';

class TrueCoachWebInterfaceImprovements extends Component{
  constructor(props){
    super(props);
    this.viewName = "TrueCoachWebInterfaceImprovements";
  }
  componentDidUpdate(){
    // console.log("did update", this);
  }
  componentDidMount(){
    // console.log("did mount", this);
    window.scrollTo({ top: 0 });
  }
  
  render(){
    return (
      <div>
        <SubpageHeader eyebrowText={"Case Study"} pageHeaderText={"TrueCoach Design Systems"}  />

        <section className="hero-content subpage-hero">
          <img src="/images/tc-web.jpg" />
        </section>

        <section className="content">

          <h2 className="">
            {/*<IconInfo className="heading-icon1" />*/}
            <span>Background</span>
          </h2>

          <p>As Xplor’s Fitness & Wellbeing division aimed to unify and elevate its digital presence, the TrueCoach platform faced the challenge of integrating with a newly developed design system—Apollo Design System. We had limited resources given to this initiative. The existing TrueCoach web interface was fragmented, with inconsistent navigation, outdated color schemes, and a lack of cohesion in its information architecture. This inconsistency not only hindered user experience but also impeded the platform's ability to scale and adapt to new features.</p>
          <p>The goal was to overhaul the TrueCoach interface to align with Apollo's design principles while preserving TrueCoach’s unique brand identity. This required a comprehensive redesign of major elements, including navigation, color systems, and overall information architecture. The challenge was further complicated by the need to deliver these updates efficiently without disrupting ongoing projects or impacting the current user experience.</p>

          <h2 className="spacer-above">
            {/*<IconTree2 className="heading-icon1" />*/}
            <span>Main Navigation and Information Architecture</span>
          </h2>

          <p>We first had to find all the places in the app that were going to have to change. Aside from the top anchor navigation, there was zero consistency of information architecture or navigation scheme.</p>
          <img className="inline-img" src="/images/tc-nav-problem.jpg" alt="Navigation inconsistency" />

          <p>Because we have thousands of daily active users, we didn't want to disrupt them completely if we didn't have to. Or at least, not for something "worse" in their eyes. So, We designed a top vs left nav usability test and deployed it to the users. This is a common practice I employ for features that have ambiguity or just need answered by the users. Build clickable prototypes and put them in front of folks. For TrueCoach, the average response rate is about ~500 users per survey or usability test.</p>
          <img className="inline-img" src="/images/tc-nav-definition.jpg" alt="Navigation definition" />
          <div className="img-annotation">Image note: This is a shot from Figma, we used Maze.co via Intercom to deploy our usability tests.</div>

          <p>I redesigned and componentized the navigation system with variants within Figma for use within Figma application shells.</p>
          <img className="inline-img" src="/images/tc-nav-variants.jpg" alt="Navigation variants" />

          <p>The redesign included a responsive navigation system with scalable views for full-screen, collapsed, and mobile layouts.</p>
          
          <img className="inline-img" src="/images/tc-nav-responsive.jpg" alt="Navigation responsive" />

          <p>Along with the main navigation, we also needed to redesign the top level content navigation and information architecture. This includes header, subheader, main and secondary page actions, search and content filtering functionality, and content navigation elements like pagination.</p>
          
          <img className="inline-img" src="/images/tc-nav-components.jpg" alt="Navigation components" />
          <div className="img-annotation">Image note: Example of components that show both light mode and dark mode.</div>

          <h2 className="spacer-above">
            {/*<IconShape className="heading-icon1" />*/}
            <span>Color System</span>
          </h2>

          <p>Once we had implemented the new navigation, it used Apollo+TrueCoach colors, but the rest of the website did not. This was an issue for me, but we didn't have the resouces to pull developers just to update some colors in CSS across the web platform. I decided to personally update the color system to integrate Apollo's color palette while maintaining TrueCoach's brand identity. Since a direct 1-to-1 match was not feasible, I carefully adjusted the Apollo colors to create a harmonious look that aligned with TrueCoach’s visual style.</p>
          
          <img className="inline-img" src="/images/tc-color-changes.jpg" alt="Color system updates" />
          <div className="img-annotation">Left to Right: Old TrueCoach colors, new TC colors based on Apollo but not 1-to-1, Apollo colors.</div>


          <p>To expedite the integration, I personally updated the front-end code outside of the regular sprint cycle, ensuring a faster and more efficient delivery of the new color scheme.</p>
          
          <img className="inline-img" src="/images/tc-color-fixes.jpg" alt="Color system fixes" />
          <div className="img-annotation">Image note: Showing my fixes to sentiment colors.</div>

          <p>Once rolled out, we got a handful of slightly mad coaches saying that the contrast for one of their most used features was now off. It was my fault for the oversight, so as soon as we saw the messages come in through intercom, I went to work to adjust those specific colors within the app that had to do with compliance and sentiment.</p>

          <img className="inline-img" src="/images/tc-color-oops.jpg" alt="Color system oops" />
          <div className="img-annotation">Left to Right: Intercom support messages in ProductBoard, compare and fix.</div>

          <p>Within a matter of hours, I was able to address the concerns and push up a pull request (PR) to fix the issues myself. Luckly, we didn't have any issues after that point and in the case of a base color change, no news is good news.</p>

          <p>Now the product has a seamless UI design base that's mostly Apollo making it easier for the component libraries to be shared across the Xplor products.</p>

          <img className="inline-img" src="/images/tc-color-beforeafter.jpg" alt="Color system before after" />
          <div className="img-annotation">Image Note: Dark mode shows base colors before (left) color update and after (right) color update.</div>

          <h3 className="spacer-above">Apollo Design System Integration</h3>
          <p>Our Product Design Team developed the Apollo Design System for Xplor’s Fitness & Wellbeing division. Each team member played a crucial role in crafting and adapting the core system to meet the needs of various brands within the organization. Our contribution involved merging Apollo's design principles with TrueCoach's requirements, ensuring a consistent and high-quality user experience across platforms.</p>
          <p>Although I don't want to explain this entire design system here in my portfolio, I'll drop a couple of screenshots. If you want to learn more about Apollo, reach out and I can give you some links.</p>

          <img className="inline-img" src="/images/tc-apollo-structure.jpg" alt="Apollo Design System structure" />
          <img className="inline-img" src="/images/tc-apollo-buttons.jpg" alt="Apollo Design System buttons" />

          <h2 className="spacer-above">
            {/*<IconTrendUpSquare className="heading-icon1" />*/}
            <span>Results</span>
          </h2>

          <p>Although it's hard to quantify design efforts that are strictly based on information architecture and visual design with churn, conversion and other hard metrics. It's easy to see the difference that it makes with brand equity, NPS customer feedback about "design quality" and sentiment about the overall design of a product.</p>
          <ul>
            <li>Consistently delivered visually noticable updates to the software that coaches are using daily, giving them a sense of trust that we're listening to their feedback</li>
            <li>Navigation restructure makes for a easier feature findability for new users, but even for experienced users who couldn't find certain features</li>
            <li>We created a visually appealing and functionally robust interface that supports the platform's growth and future development</li>
            <li>Streamlined navigation and updated color system contributed to improved usability and user satisfaction</li>
            <li>Integration of the Apollo Design System into TrueCoach's web interface resulted in a more intuitive and cohesive user experience</li>
            <li>Inspired new components for the Apollo Design System that can now be used on the greater Xplor design team</li>
          </ul>


          <h2 className="spacer-above">What I've learned personally</h2>
          <ul>
            <li>Although it's not usually the prefered method, by circumventing the standard sprint process and working directly with a senior front-end engineer, I was able to accelerate the implementation of these changes, enabling us to deliver enhancements without interrupting ongoing projects. Felt a little startupy, but I'm here to get things done and leave things better than how I found them.</li>
            <li>Maintaining a design system takes dedicated resources otherwise it becomes more of a "design guideline" and users borrow components just to disassemble them</li>
            <li>Building a couple Figma plugins to reflow data into complex components and see how they may fall apart with "too much text" was a huge win for me and my team</li>
          </ul>
        
          <h2 className="spacer-above">Thanks for reading 🎉</h2>
          <p>If there is anything you'd like further clarification on or are just kinda curious, feel free to reach out to me.</p>

        </section>
        <Footer />
      </div>
    )
  }
}

export default TrueCoachWebInterfaceImprovements;

