import React from 'react';
import HomeHeader from './header/HomeHeader';
import Footer from './Footer';
import CaseStudies from './CaseStudies';
import LogoGarden from './header/LogoGarden';

class Home extends React.Component{
  render(){  
    return (
      <div>
        <HomeHeader />
        <LogoGarden />
        <CaseStudies />
        <Footer />
      </div>
    );
  }
}

export default Home;