import React, {Component} from 'react';
import SubpageHeader from '../header/SubpageHeader';
import Footer from '../Footer';

class TrueCoachOnboarding extends Component{
  constructor(props){
    super(props);
    this.viewName = "TrueCoachOnboarding";
  }
  componentDidUpdate(){
    // console.log("did update", this);
  }
  componentDidMount(){
    // console.log("did mount", this);
    window.scrollTo({ top: 0 });
  }
  
  render(){
    return (
      <div>
        <SubpageHeader eyebrowText={"Case Study"} pageHeaderText={"TrueCoach Onboarding"}  />

        <section className="hero-content subpage-hero">
          <img src="/images/tc-onboarding.jpg" />
        </section>

        <section className="content">
          <h3>Background</h3>
          <p>The onboarding process for new customers, particularly coaches, is crucial for user retention and satisfaction. To improve this process, we undertook a comprehensive review and redesign of our onboarding flow. The focus was on optimizing the signup page, streamlining onboarding steps, and evaluating the effectiveness of our onboarding strategies.</p>

          <h3>The Task</h3>
          
          <p>I worked closely with the marketing team to implement incremental improvements to the signup page. Our goal was to increase conversion rates by making the page more user-friendly and effective.</p>
          <p>I focused on identifying and removing any sources of confusion or friction in the onboarding process. This helped ensure that users could efficiently reach key conversion points. Additionally, I incorporated engaging illustrated graphics to enhance the overall user experience and direct attention to the onboarding steps.</p>
          {/*<p>Onboarding Walkthrough Section: I tested the impact of providing a comprehensive walkthrough of platform features right from the start. This approach aimed to boost conversions, and I continuously evaluated and refined it based on user feedback to ensure its effectiveness.</p>*/}

          <h3>What We Did</h3>
          <p><strong>Signup Page Iterations</strong><br/>
          We worked closely with the marketing team to make targeted improvements to the signup page. Each iteration focused on addressing specific pain points and enhancing the user experience to incrementally boost conversion rates. We outlined experiment metrics and did month long tests to see if we should keep or revert any changes made to the signup page.</p>
          <p><strong>Streamlining Onboarding Steps</strong><br/>We removed any sources of friction in the onboarding process and introduced playful graphics to guide users through critical triggers for conversion. This led to a noticeable increase in conversions.</p>
          <p><strong>Onboarding Walkthrough Section Test</strong><br/>We tested a detailed walkthrough of platform features to help users understand the product’s value. However, usability testing revealed that this approach was overwhelming for users and did not improve conversions. As a result, we removed the walkthrough section after one month.</p>

          <h3>Results</h3>
          <ul>
              <li><strong>Incremental Conversion Growth:</strong> The iterative enhancements to the signup page and onboarding process resulted in a steady increase in conversion rates.</li>
              <li><strong>Enhanced User Engagement:</strong> Streamlining onboarding steps and incorporating engaging graphics led to a more enjoyable user experience, improving conversion metrics.</li>
              <li><strong>Refined Onboarding Approach:</strong> The review and subsequent removal of the onboarding walkthrough section helped us focus on more effective onboarding strategies, contributing to overall user satisfaction.</li>
          </ul>

        </section>
        <Footer />
      </div>
    )
  }
}

export default TrueCoachOnboarding;
