import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import TrueCoachMobileOverhaul from './components/caseStudies/TrueCoachMobileOverhaul';
import TrueCoachOnboarding from './components/caseStudies/TrueCoachOnboarding';
import TrueCoachFeatureAdoption from './components/caseStudies/TrueCoachFeatureAdoption';
import TrueCoachRevenueFeatures from './components/caseStudies/TrueCoachRevenueFeatures';
import TrueCoachWebInterfaceImprovements from './components/caseStudies/TrueCoachWebInterfaceImprovements';

class App extends React.Component{
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/case-studies/truecoach-onboarding" element={<TrueCoachOnboarding />} />
          <Route path="/case-studies/truecoach-mobile-overhaul" element={<TrueCoachMobileOverhaul />} />
          <Route path="/case-studies/truecoach-adoption" element={<TrueCoachFeatureAdoption />} />
          <Route path="/case-studies/truecoach-revenue-features" element={<TrueCoachRevenueFeatures />} />
          <Route path="/case-studies/truecoach-web-interface-improvements" element={<TrueCoachWebInterfaceImprovements />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
